













































import { Component, Inject, Vue } from "vue-property-decorator";
import TaskStatusChip from "./TaskStatusChip.vue";

import { BackendClient, Task } from "ibf-backend";
import moment from "moment-timezone";

@Component({
  components: {
    TaskStatusChip,
  },
})
export default class Tasks extends Vue {
  @Inject("backendClient") client!: BackendClient;

  private tasks = Array<Task>();

  private headers = [
    {
      value: "id",
      text: "Id",
    },
    {
      value: "status",
      text: "Statut",
    },
    {
      value: "type",
      text: "Type",
    },
    {
      value: "creationDate",
      text: "Date de création",
    },

    { text: "Actions", value: "actions", sortable: false },
  ];

  private timer: any = null;

  private getTz() {
    return moment.tz.guess();
  }

  mounted() {
    this.populateTasks();
    this.timer = setInterval(() => {
      this.populateTasks();
    }, 2000);
  }

  beforeDestroy() {
    clearInterval(this.timer);
  }
  async populateTasks() {
    this.tasks = await this.client.taskApi.getTasks();
  }

  private showTask(item: any) {
    this.$router.push({ path: `/task/${item.id}` });
  }

  async deleteTask(item: any) {
    await this.client.taskApi.deleteTask(item.id);
    this.populateTasks();
  }
}
