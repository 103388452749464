import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VRow,{staticClass:"text-center"},[_c(VCol,{staticClass:"mb-4"},[_c('h1',{staticClass:"display-1 font-weight-bold mb-3"},[_vm._v(" Tasks ")])])],1),_c(VRow,{staticClass:"text-center"},[_c(VCol,{staticClass:"mb-4"},[_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.tasks},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VIcon,{staticClass:"mr-2",on:{"click":function($event){return _vm.showTask(item)}}},[_vm._v(" mdi-eye ")]),_c(VIcon,{staticClass:"mr-2",on:{"click":function($event){return _vm.deleteTask(item)}}},[_vm._v(" mdi-delete ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{on:{"click":function($event){return _vm.showTask(item)}}},[_c('task-status-chip',{attrs:{"item":item}})],1)]}},{key:"item.creationDate",fn:function(ref){
var item = ref.item;
return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm._f("moment")(item.creationDate,"from", "now")))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm._f("moment")(item.creationDate,"timezone", _vm.getTz(), "dddd, MMMM Do YYYY, h:mm:ss a")))])])]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }