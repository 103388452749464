









import { Task } from "ibf-backend";
import { TaskStatus } from "ibf-backend";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";

@Component
export default class TaskStatusChip extends Vue {
  @Prop() item!: Task;
  private icon: string = "mdi-alarm";
  private text: string = "Pending";
  private color: string = "orange";

  @Watch("item", { immediate: true, deep: true })
  itemChanged(val: Task, oldVal: Task) {
    if (val && val.status) {
      const enumStatus: TaskStatus = (<any>TaskStatus)[val.status];
      switch (+enumStatus) {
        case TaskStatus.PENDING:
          this.icon = "mdi-alarm";
          this.text = "Pending";
          this.color = "orange";
          return;
        case TaskStatus.SUCCESS:
          this.icon = "mdi-check";
          this.text = "Success";
          this.color = "green";
          return;
        case TaskStatus.RUNNING:
          this.icon = "mdi-play";
          this.text = "Running";
          this.color = "blue";
          return;

        case TaskStatus.CANCELED:
          this.icon = "mdi-stop";
          this.text = "Canceled";
          this.color = "black";
          return;
        case TaskStatus.ERROR:
        default:
          this.icon = "mdi-alert-circle-outline";
          this.text = "Error";
          this.color = "red";
          return;
      }
    }
  }
}
